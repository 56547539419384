import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import styles from "../styles/pages/services.module.css"

import mainimg from "../images/icon.png"

const ServicesPage = () => (
	<Layout activePath='services' pageTitle="Our Services">
		<div className={"text-centered"}>
			<img className={styles.mainimg} src={mainimg} alt=""/>
			<div className={styles.maincontent}>
				<h1>Our Services</h1>
				<div>
					As a registered broker/dealer in securities, our company caters to both retail and institutional clients in the region. We assist clients who want to invest in securities and to avail of Initial Public Offerings, among others.
				</div>
				<Link className={styles.mainlink+" color-background-gray-6f"} to={"/contact-us"}>Find out more</Link>
			</div>
		</div>
	</Layout>
)

export default ServicesPage
